<template>
  <div class="bonusesCard">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="8" xl="6" lg="6" md="6" sm="8" class="py-0">
        <span>Придбання квитків</span>
        <p class="bonusesCardText" style="margin-bottom: 0px; margin-top: 4px">
          {{
            new Date(transaction?.order?.paid_at).toLocaleDateString("uk-UA", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })
          }}
        </p>
      </v-col>
      <v-col cols="4" class="py-0" v-if="!$vuetify.breakpoint.smAndDown">
        <p style="margin-bottom: 0px">
          <span class="bonusesCardText"
            >{{ "trip_label" | localize }}:&nbsp;</span
          >{{ transaction?.order?.departure?.translations?.[0]?.name }} - {{ transaction?.order?.destination?.translations?.[0]?.name }}
        </p>
      </v-col>
      <v-col cols="2" style="text-align: right" class="py-0">
        <span :style="transaction.type == 'Income' ? 'color: #149e51' : 'color: #DF3A3A'">{{transaction.type == 'Income' ? '+' : '-'}}{{transaction.amount}}</span>
      </v-col>
      <v-col cols="2" v-if="$vuetify.breakpoint.smAndDown">
        <v-row no-gutters justify="center">
          <img
            src="@/assets/img/iconsSvg/chevronDown.svg"
            alt="down"
            style="cursor: pointer"
            class="chevronDown"
            @click="isDetail = !isDetail"
            :class="{ rotated: isDetail }"
          />
        </v-row>
      </v-col>
      <v-expand-transition>
        <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown && isDetail">
          <div class="sectionLine" style="margin: 20px 0px" />
          <p style="margin-bottom: 0px">
            <span class="bonusesCardText"
              >{{ "trip_label" | localize }}:&nbsp;</span
            >{{ transaction?.order?.departure?.translations?.[0]?.name }} - {{ transaction?.order?.destination?.translations?.[0]?.name }}
          </p>
        </v-col>
      </v-expand-transition>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    isDetail: false,
  }),
  props: {
    transaction: {
      require: true
    }
  }
};
</script>

<style scoped>
.bonusesCard {
  border-bottom: 1px solid #f5f5f5;
  padding: 12px 0px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #fafafa;
}
.bonusesCardText {
  color: #4b5262;
  font-size: 14px;
  font-weight: 400;
}
.chevronDown {
  transition: transform 0.3s ease-in-out;
}

.chevronDown.rotated {
  transform: rotate(180deg);
}
</style>