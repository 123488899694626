<template>
  <v-col style="text-align: left" class="px-0 py-0">
    <h3 class="pageName">{{ "my_bonuses_label" | localize }}</h3>
    <!-- BONUSES LIST -->
    <Loader v-if="showLoader" />
    <v-col v-else class="px-0 py-0">
      <v-col
        class="px-0 py-0"
        style="margin-top: 20px"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <bonus-card
          v-for="transaction in transactionList"
          :key="transaction.id"
          :transaction="transaction"
        />
      </v-col>
      <v-col v-else class="px-0 py-0" style="margin-top: 20px">
        <bonus-card
          v-for="transaction in transactionList"
          :key="transaction.id"
          :transaction="transaction"
        />
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import profileService from "../../../../requests/User/profileService";
import Loader from "../../../UI/Loader.vue";
import bonusCard from "./bonusCard.vue";
export default {
  components: { bonusCard,Loader },
  data: () => ({
    transactionList: [],
    showLoader: true
  }),
  mounted() {
    this.getTransactions();
  },
  methods: {
    async getTransactions() {
      await profileService.getBonuses().then((res) => {
        if (res.status == "Success") {
          this.transactionList = res.data;
          this.showLoader = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.bonusesCard {
  border-bottom: 1px solid #f5f5f5;
  padding: 12px 0px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #fafafa;
}
.bonusesCardText {
  color: #4b5262;
  font-size: 14px;
  font-weight: 400;
}
</style>